import React from 'react';
import { Dropdown } from 'react-bootstrap';

import UserOfficeSvg from '../../../../assets/img/user_office.svg';

export default function PatientName({ patient, patients, user }) {
  const getName = () => {
    if (!patients.data.length || (!patient && patients.data.length === 1)) return null;

    return patient ? patient.full_name : 'Оберіть пацієнта';
  };

  const userPhone = user?.name
    ? `+38 ${user.name.slice(0, 3)} ${user.name.slice(3, 6)} ${user.name.slice(6, 8)} ${user.name.slice(8, 10)}`
    : '';

  return (
    <Dropdown.Toggle
      id="dropdown-basic"
      className={!patients.data.length || patients.data.length === 1 ? 'single' : ''}
    >
      <div className="user-cabinet-navigation__item-description-icon">
        <img src={UserOfficeSvg} alt="user" />
      </div>
      <div className="user-cabinet-navigation__item-description-content">
        {getName()}
        <div className="user-cabinet-navigation__item-description-content-number">{userPhone}</div>
      </div>
      {patients.data.length > 1 && (
        <div className="user-cabinet-navigation__item-description-icon-dropdown">
          <i className="fas fa-chevron-down" />
        </div>
      )}

      {!patient && !localStorage.getItem('patientId') && (
        <div className="user-cabinet-navigation__item-tooltip">
          <div className="user-cabinet-navigation__item-tooltip-icon">
            <i className="fas fa-info-circle" />
          </div>
          <div className="user-cabinet-navigation__item-tooltip-description">
            Для того, щоб переглянути особистий кабінет, оберіть пацієнта
          </div>
        </div>
      )}
    </Dropdown.Toggle>
  );
}
