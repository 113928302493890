import React from 'react';
import Button from 'react-bootstrap/Button';

const Confirmation = ({ handleSubmit, onSubmit, patients, time, onClose, watch, user: { declarationSigned } }) => {
  const patient = patients.data.find((patient) => patient.id === Number(watch('patient')));

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="confirmation_of_entry success">
      { patient ? (
        <>
          <p className="appointment-auth-text">
            <span className="name">Пацієнт&nbsp;{patient?.full_name}&nbsp;</span>
            {patient?.sex === 'male' ? 'записаний' : 'записана'} на прийом успішно!
          </p>
          <p className="appointment-confirmation-text">
            Просимо завітати до закладу не пізніше, ніж о <span className="time">{time}</span> та повідомити
            реєстратуру.
          </p>
        </>
      ) : (
        <p>Запис на прийом успішно завершено</p>
      )}
      <Button type="submit" variant="primary" className="submit-button" onClick={onClose}>
        Закрити
      </Button>
    </form>
  );
};

export default Confirmation;
