import Appointments from './pages/Appointments';
import PatientInfo from './pages/Patient';
import RedIndicators from './pages/Indicators';
import Doctor from './pages/Doctor';
import Recipes from './pages/Recipes';
import Examinations from './pages/Examinations';
// import ServiceRequests from './pages/ServiceRequests';
import Vaccinations from './pages/Vaccinations';
import Visits from './pages/Visits';

import withLoading from '../../HOC/withLoading';
import AppointmentsSvg from '../../assets/img/appointments.svg';
import VisitsSvg from '../../assets/img/contacts.svg';
import RecipesSvg from '../../assets/img/recipes.svg';
import FamilyDoctorSvg from '../../assets/img/family_doctor.svg';
import MyInfoSvg from '../../assets/img/my_info.svg';
import IndicatorsSvg from '../../assets/img/indicators.svg';
import ExaminationsSvg from '../../assets/img/examination.svg';
import VaccinationsSvg from '../../assets/img/vaccinations.svg';
// import ServiceRequestSvg from '../../assets/img/medical-history.svg';

export const routes = [
  {
    path: '/appointments',
    linkLabel: 'Записи на прийом',
    Component: Appointments,
    icon: AppointmentsSvg,
    isMenuItem: true,
    regExp: /^\/appointments$/,
  },
  {
    path: '/recipes',
    linkLabel: 'Призначені ліки',
    Component: Recipes,
    icon: RecipesSvg,
    isMenuItem: true,
    regExp: /^\/recipes$/,
  },
  {
    path: '/doctor',
    linkLabel: 'Мій сімейний лікар',
    Component: withLoading(Doctor),
    icon: FamilyDoctorSvg,
    isMenuItem: true,
    onlyWithDeclaration: true,
    regExp: /^\/doctor$/,
  },
  {
    path: '/red-indicators',
    linkLabel: 'Мої важливі показники',
    Component: withLoading(RedIndicators),
    icon: IndicatorsSvg,
    isMenuItem: true,
    regExp: /^\/red-indicators$/,
  },
  {
    path: '/visits/:id',
    linkLabel: 'Візити до лікарів',
    Component: Visits,
    icon: VisitsSvg,
    isMenuItem: false,
    regExp: /^\/visits\/\d+$/,
  },
  {
    path: '/visits',
    linkLabel: 'Візити до лікарів',
    Component: Visits,
    icon: VisitsSvg,
    isMenuItem: true,
    regExp: /^\/visits$/,
  },
  {
    path: '/vaccinations',
    linkLabel: 'Щеплення',
    Component: withLoading(Vaccinations),
    icon: VaccinationsSvg,
    isMenuItem: true,
    regExp: /^\/vaccinations$/,
  },
  {
    path: '/examinations',
    linkLabel: 'Профілактичні огляди',
    Component: withLoading(Examinations),
    icon: ExaminationsSvg,
    isMenuItem: true,
    regExp: /^\/examinations$/,
  },
  // {
  //   path: '/service-requests',
  //   linkLabel: 'Мої направлення',
  //   Component: withLoading(ServiceRequests),
  //   icon: ServiceRequestSvg,
  //   isMenuItem: true,
  //   onlyWithDeclaration: true,
  //   regExp: /^\/service-requests$/,
  // },
  {
    path: '/info',
    linkLabel: 'Моя інформація',
    Component: withLoading(PatientInfo),
    icon: MyInfoSvg,
    isMenuItem: true,
    regExp: /^\/info$/,
  },
];
