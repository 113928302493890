import React from 'react';
import { Modal } from 'react-bootstrap';

import { months as monthNames } from '../../../../../../constants';
import { useAppointmentsCalendarModalApi } from './api';
import Week from './week';
import AppointmentsCalendarDaySubModal from './subModalDay';

export default function AppointmentsCalendarModal({ show, onClose, doctor, city, byDaysSchedule = {} }) {
  const {
    dayData,
    setDayData,
    subModalIsVisible,
    setSubModalIsVisible,
    weeksRange,
    now,
  } = useAppointmentsCalendarModalApi({
    byDaysSchedule,
    doctor,
  });

  if (!show) return null;

  return (
    <Modal show={show} onHide={onClose} className="modal-appointments-calendar" centered>
      <Modal.Header closeButton>
        <div className="calendar-head__title">
          <i className="far fa-calendar-alt" /> Календар прийому
        </div>
        <div className="calendar-head__description">
          <span className="full_name">{doctor.full_name}</span>
          <span className="divider">&nbsp;|&nbsp;</span>
          <span className="date">
            {monthNames[now.format('MMMM')].full} {now.format('YYYY')}
          </span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="calendar-wrapper">
          <div className="calendar">
            {weeksRange.map((weekDays, index) => (
              <Week
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                weekDays={weekDays}
                now={now}
                city={city}
                setDayData={setDayData}
                showSubModal={() => setSubModalIsVisible(true)}
              />
            ))}
          </div>
          <div className="calendar-footer">Прийом доступний на найближчі 4 тижні</div>
        </div>
      </Modal.Body>
      <AppointmentsCalendarDaySubModal
        show={subModalIsVisible}
        dayData={dayData}
        jobs={doctor.jobs}
        city={city}
        onClose={() => setSubModalIsVisible(false)}
      />
    </Modal>
  );
}
