import { useCallback, useEffect, useState, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { routes } from './routes';

export function useVaccinationsApi({ patients, fetchPatients }) {
  const { params } = useRouteMatch();
  const [currentPatient, setCurrentPatient] = useState();
  const [personalCodeModalIsVisible, setPersonalCodeModalIsVisible] = useState(false);
  const token = localStorage.getItem('token');
  const storagePatientId = localStorage.getItem('patientId');
  const declarationSigned = localStorage.getItem('declarationSigned') === 'true';

  const currentPage = useMemo(() => {
    const currentRoute = `/${params.pageName}${params.order ? `/${params.order}` : ''}`;

    return routes.find((route) => route.regExp.test(currentRoute));
  }, [params]);

  const setPatient = useCallback(
    (patientId) => {
      const patient = patients.data.find((patient) => +patient.id === +patientId);

      if (patient) {
        localStorage.setItem('patientId', patient.id);
        setCurrentPatient(patient);
      }
    },
    [patients],
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (token && !patients.loading && !patients.loaded) fetchPatients();
  }, [token, declarationSigned, fetchPatients, patients.loaded, patients.loading]);

  useEffect(() => {
    if (storagePatientId && !currentPatient && patients.data.length) {
      const patient = patients.data.find((patient) => +patient.id === +storagePatientId);
      setCurrentPatient(patient);
    }
  }, [currentPatient, patients.data, storagePatientId]);

  useEffect(() => {
    if (!storagePatientId && !currentPatient && patients.data.length) {
      localStorage.setItem('patientId', patients.data[0].id);
      setCurrentPatient(patients.data[0]);
    }
  }, [patients, currentPatient, storagePatientId]);

  return {
    currentPatient,
    setPatient,
    personalCodeModalIsVisible,
    setPersonalCodeModalIsVisible,
    storagePatientId,
    currentPage,
  };
}
