import { useCallback, useState } from 'react';
import moment from 'moment';

const transformPhoneNumber = (phone) => phone.replace('(', '').replace(')', '').replace(/ /gi, '');

export function useAppointmentApi({
  appointment,
  doctor,
  timeSlot = {},
  onClose,
  clearAuthState,
  getDoctorSchedule,
  updateAppointmentState,
  makeAnAppointment,
  makeAnOperatorAppointment,
  makeReservation,
  findOperatorPatients,
  makeAnOperatorReservation,
  isVaccinates = false,
  isOperator = false,
  websiteCovidSource = null,
}) {
  const [isVisibleModalBot, setIsVisibleModalBot] = useState(false);
  const [isOperatorChooseOtherPatient, setIsOperatorChooseOtherPatient] = useState(false);
  const [referralNumber, setReferralNumber] = useState('');

  const getSchedule = useCallback(() => {
    const now = moment(localStorage.getItem('datetime') ?? undefined);
    console.log(now)
    console.log(now.add(4, 'w').format('YYYY-MM-DD'))
    const dates = {
      date_from: now.format('YYYY-MM-DD'),
      date_to: now.add(4, 'w').format('YYYY-MM-DD'),
    };

    getDoctorSchedule({ ...dates, ids: doctor.id, replace: true, isVaccinates });
  }, [doctor.id, getDoctorSchedule, isVaccinates]);

  const handleClearAppointmentState = useCallback(() => {
    updateAppointmentState();
    setIsOperatorChooseOtherPatient(false);
  }, [setIsOperatorChooseOtherPatient, updateAppointmentState]);

  const handleClose = useCallback(() => {
    onClose();
    clearAuthState();
    setIsOperatorChooseOtherPatient();
    setReferralNumber('');

    if (appointment.success) {
      getSchedule();
      setIsVisibleModalBot(true);
    }

    updateAppointmentState();
  }, [appointment.success, clearAuthState, getSchedule, onClose, updateAppointmentState]);

  const operatorAppointment = useCallback(
    (values) => {
      
      const commonProps = {
        work_date: moment(timeSlot.datetime).format('YYYY-MM-DD'),
        time_from: moment(timeSlot.datetime).format('HH:mm'),
        birthday: moment(values.birthday).format('YYYY-MM-DD'),
        doctor_id: doctor.id,
        job_id: timeSlot.job_id,
        is_vaccination: Number(isVaccinates),
        call_centre_user_code: values.operatorCode,
        phone: transformPhoneNumber(values.phone),
        is_video_conference: values?.is_video_conference
      };

      if (values.operatorCode && values.phone && !appointment.operatorPatientsLoaded) {
        findOperatorPatients(transformPhoneNumber(values.phone));
      }

      if (values.operatorCode && values.phone && values.patient && values.patient !== 'other') {
        makeAnOperatorAppointment({
          ...commonProps,
          patient_id: values.patient,
        }).catch(() => {
          getSchedule();
          onClose();
        });
      }

      if (values.operatorCode && values.phone && values.patient === 'other') {
        setIsOperatorChooseOtherPatient(true);
      }

      if (values.name) {
        makeAnOperatorReservation({
          ...commonProps,
          name: values.name,
          last_name: values.last_name,
          birthday: values.birthday,
        });
      }
    },
    [
      timeSlot,
      doctor.id,
      isVaccinates,
      appointment.operatorPatientsLoaded,
      findOperatorPatients,
      makeAnOperatorAppointment,
      getSchedule,
      onClose,
      makeAnOperatorReservation,
    ],
  );

  const onSubmit = useCallback(
    (values) => {
      
      if (isOperator) {
        operatorAppointment(values);
        return;
      }
      
      const commonParameters = {
        work_date: moment(timeSlot.datetime).format('YYYY-MM-DD'),
        time_from: moment(timeSlot.datetime).format('HH:mm'),
        birthday: moment(values.birthday, "DD-MM-YYYY").format('YYYY-MM-DD'),
        doctor_id: doctor.id,
        job_id: timeSlot.job_id,
        is_vaccination: Number(isVaccinates),
        referral_number: values.referral_number,
        is_video_conference: values?.is_video_conference
      };

      if (websiteCovidSource) {
        commonParameters.source = websiteCovidSource;
      }

      if (localStorage.getItem('declarationSigned') === 'true') {
        makeAnAppointment({
          body: commonParameters,
          patient_id: values.patient,
        }).catch(() => {
          getSchedule();
          onClose();
        });
      } else {
        makeReservation({
          ...commonParameters,
          name: values.name,
          last_name: values.last_name,
          reserv_comment: values.reserv_comment,
        });
      }
    },
    [ 
      isOperator,
      timeSlot,
      doctor.id,
      isVaccinates,
      websiteCovidSource,
      operatorAppointment,
      makeAnAppointment,
      getSchedule,
      onClose,
      makeReservation,
    ],
  );

  return {
    handleClearAppointmentState,
    isOperatorChooseOtherPatient,
    isVisibleModalBot,
    onSubmit,
    handleClose,
    setIsVisibleModalBot,
    referralNumber,
    setReferralNumber,
  };
}
