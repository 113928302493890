import React, { useMemo } from 'react';
import moment from 'moment';

import { daysOfWeek } from '../../constants';

const InfoBox = ({ doctor, phone, timeSlot, user, isOperator, watch, operatorPatientsLoaded }) => {
  const time = moment(timeSlot.datetime).format('HH:mm');
  const fullDate = moment(timeSlot.datetime).format('DD.MM.YYYY');
  const dayOfWeek = daysOfWeek.find((day) => day.en === moment(timeSlot.datetime).format('dddd')).full;

  const job = doctor.jobs.find(({ id }) => id === timeSlot.job_id);

  const regPhone = useMemo(() => {
    if (!job.division?.contacts) return null;

    return job.division.contacts.reduce(
      (acc, contact) => (contact.type === 'phone' ? `${acc.length ? `${acc}; ` : ''}${contact.value}` : acc),
      '',
    );
  }, [job.division]);

  const regEmail = useMemo(() => job.division?.contacts?.find((contact) => contact.type === 'email')?.value, [
    job.division,
  ]);
  
  return (
    <>
      <div className="info_box appointment">
        <div className="doctor">
          <span>Лікар</span>
          <p>{doctor.full_name}</p>
        </div>
        <div className="reception_time">
          <span>Час прийому</span>
          <p>
            {fullDate}, {dayOfWeek.toLowerCase()}, {time}
          </p>
        </div>
        <div className="specialization">
          <span>Спеціалізація</span>
          <p>{job?.position?.name}</p>
        </div>
        <div className="institution">
          <span>Заклад</span>
          <p>{job?.division.full_name}</p>
        </div>
        {timeSlot.cabinet && (
          <div className="specialization">
            <span>Кабінет</span>
            <p>{timeSlot.cabinet}</p>
          </div>
        )}
        <div className="institution">
          <span>Адреса</span>
          <p>{job?.division.address.display_text ?? 'Немає даних'}</p>
        </div>

        {regPhone && (
          <div className="specialization">
            <span>Телефони реєстратури</span>
            <p>{regPhone}</p>
          </div>
        )}

        {regEmail && (
          <div>
            <span>E-mail реєстратури</span>
            <p>
              <a href={`mailto:${regEmail}`}>{regEmail}</a>
            </p>
          </div>
        )}

        {operatorPatientsLoaded && (
          <>
            <div className="operator-code" />
            {watch('operatorCode') && (
              <div className="specialization ">
                <span>Номер оператора</span>
                <p>{watch('operatorCode')}</p>
              </div>
            )}
            {watch('phone') && (
              <div className="specialization">
                <span>Номер пацієнта</span>
                <p>{watch('phone')}</p>
              </div>
            )}
          </>
        )}
      </div>

      {!isOperator && !user.token && !phone && (
        <div className="warning">
          Увага! Якщо Ви не маєте мобільного телефону або не бажаєте його вказувати, запис на прийом можливий тільки
          через реєстратуру закладу {job?.division.reg_phone && '(адреса та телефон вказані вище)'}
        </div>
      )}
    </>
  );
};
export default InfoBox;
